<template>
  <div class="cmsUserList contentsWidth mx-auto mt-4">
    <div class="subject">
      <p class="title">管理者 - 一覧</p>
    </div>

    <div class="contentsWidth mx-auto mt-4">
      <div class="bold">絞り込み条件</div>
      <div class="search_area">
        <b-row class="mt-2">
          <b-col>
            <label class="mb-0">氏名</label>
          </b-col>
          <b-col sm="6">
            <label class="mb-0">権限</label>
          </b-col>

          <b-col sm="6">
            <b-form-input
              class="h-50px"
              v-model="search.name"
              type="search"
              placeholder="入力してください">
            </b-form-input>
          </b-col>
          <b-col sm="6">
            <b-form-select
              class="h-50px"
              v-model="search.authes"
              :options="authList">
              <template v-slot:first>
                <option :value="''">-- 未選択 --</option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock mr-2">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
            <div class="inlineBlock">
              <b-link
                to="/cms/cmsUser/create">新規登録</b-link>
            </div>
          </div>
        </div>
      </div>


      <div id="tableWrap" class="mt-2">

        <b-table
        id='fieldTable'
        hover
        show-empty
        :items="cmsUserList"
        :fields="header"
        :filter="search"
        :filter-function="filtering"
        :current-page="currentPage"
        :per-page="perPage"
        thead-class="tableHead"
        tbody-tr-class="dataWrap"
        table-class="icon-white"
        @filtered="onFiltered"
        >
          <template v-slot:emptyfiltered="scope">
            <p class="none-data">検索結果がありません。</p>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1 select-pop-over-trigger">選択</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 select-pop-over" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 select-pop-over" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 select-pop-over" @click="allSelect">全選択</p>
                <p class="mb-0 select-pop-over" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedIdList($event)"
                :checked="selectedIdList"
                :value="row.item.user_id"
                />
            </div>
          </template>
          <template v-slot:cell(authes)="row">
            <span v-for="(value, key) in row.value" :key="key">
              <small v-if="key!==0"> </small>
              {{ authItems[value.auth_id] }}
            </span>
          </template>

          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>

          <template v-slot:cell(btn)="row">
            <router-link
              class="edit bold"
              :to="'/cms/cmsUser/edit/'+row.item.user_id">
            詳細</router-link>
          </template>
        </b-table>
      </div>
      <div class="contentsWidth mt-2" v-if="totalRows > 0">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock mr-2">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottonArea flex flexCenter mt-5">
      <b-button class="btn btn-lg mr-2 bold" to="/cms/top">トップに戻る</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg mr-2 bold ml-2"
        :disabled="this.selectedIdList.length < 1"
        to="/cms/cmsUser/mail">メール送信</b-button>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';

export default {
  name: 'CmsContentscmsUserList',
  data() {
    return {
      authList: [],
      authItems: [],
      cmsUserList: [],
      header: [
        { key: 'checkbox', label: '', sortable: false },
        {
          key: 'kana',
          label: '氏名',
          sortable: true,
        },
        {
          key: 'affiliated_institution',
          label: '所属機関',
          sortable: true,
        },
        {
          key: 'position',
          label: '役職',
          sortable: true,
        },
        {
          key: 'isActive',
          label: 'ログイン',
          formatter: (v) => {
            return v ? '可' : '負荷';
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'authes',
          label: '権限',
          sortable: false,
        },
        {
          key: 'btn',
          label: '',
          sortable: false,
        },
      ],
      pageOptions: [10, 20, 50],
      isInit: true,
      oldPage: 1,
    };
  },
  methods: {
    filtering(lineData, search) {
      const searchName = search.name === '' || lineData.name.includes(search.name);
      const searchAuth = search.authes === '' || lineData.authes.some((v) => {
        return v.auth_id === search.authes;
      });
      return searchName && searchAuth;
    },
    async getCmsUsers() {
      const requests = [api.send('/api/user/cms/auth'), api.send('/api/user/cms/list')];
      const items = await api.all(requests).catch(() => false);
      if (items) {
        this.authList = items[0].data.authList;
        this.authItems = items[0].data.authItems;
        this.cmsUserList = items[1].data.cmsUser;
        this.$store.commit('cmsUserSearch/setTotalRows', this.cmsUserList.length);
        this.$store.commit('cmsUserSearch/setFilterdIdList', this.cmsUserList);
      }
      this.$store.dispatch('page/offLoading');
    },
    onFiltered(filteredItems) {
      this.$store.commit('cmsUserSearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('cmsUserSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('cmsUserSearch/setCurrentPage', 1);
      }
      this.$store.commit('cmsUserSearch/setFilterdIdList', filteredItems);
    },
    edit(item) {
      window.location.assign(`/cms/cmsUser/edit/${item.user_id}`);
    },
    allSelect() {
      this.$store.commit('cmsUserSearch/allSelectedId');
    },
    allRemove() {
      this.$store.commit('cmsUserSearch/initSelectedId');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((id) => {
        this.$store.commit('cmsUserSearch/addSelectedId', id);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((id) => {
        this.$store.commit('cmsUserSearch/removeSelectedId', id);
      });
    },
    updateSelectedIdList(list) {
      this.$store.commit('cmsUserSearch/setSelectedId', list);
    },
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('cmsUserSearch/setFilter', param);
    },
    setCurrentPage(page) {
      this.$store.commit('cmsUserSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('cmsUserSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('cmsUserSearch/setTotalRows', value);
    },
  },
  computed: {
    search() {
      return this.$store.state.cmsUserSearch.search;
    },
    currentPage() {
      return this.$store.state.cmsUserSearch.currentPage;
    },
    perPage() {
      return this.$store.state.cmsUserSearch.perPage;
    },
    totalRows() {
      return this.$store.state.cmsUserSearch.totalRows;
    },
    selectedIdList() {
      return this.$store.state.cmsUserSearch.selectedIdList;
    },
    filterdIdList() {
      return this.$store.state.cmsUserSearch.filterdIdList;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
  // ロード画面
  created() {
    this.$store.dispatch('page/onLoading');
    this.oldPage = this.currentPage;
    this.getCmsUsers();
    this.isInit = false;
  },
};
</script>

<style scoped>
  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }
  .contentsWidth {
    width: 1080px;
    padding: 0;
    font-size: 16px;
  }
  .pagination,
  .float-right .form-group {
    margin-bottom: .5rem;
  }
  #perPageSelect {
    height: 40px;
    width: 120px;
    margin-right: 50px;
  }
  .subject {
    width: 1080px;
    border-bottom: .1rem solid #A0A9D0;
  }
  .title {
    margin-bottom: 0;
  }
  .none-data {
    margin:0;
    text-align: center;
  }
</style>
<style>
  .cmsUserList #fieldTable,
  .cmsUserList input,
  .cmsUserList .custom-select {
    font-size: 16px;
  }
  .cmsUserList .tableHead {
    background: #6E6E6E;
    color: #FFF;
    border: solid 2px #FFFFFF;
    height: 40px !important;
    word-break: keep-all;
  }
  .cmsUserList .tableHead th {
    border: solid 1px #CCCCCC;
  }
  .cmsUserList tbody {
    background: #FFF;
  }
  .cmsUserList .tableHead th:nth-child(5) {
    width: 100%;
  }
  .cmsUserList .dataWrap td:not(:nth-child(5)) {
    white-space: nowrap;
  }
  .cmsUserList .edit {
    color: #0A8EA7;
    cursor: pointer;
  }
  .h-50px {
    height: 50px;
  }
</style>
